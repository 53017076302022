import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { restApi } from '../../../common/api';
import Modal from '../../../components/Modal';
import { useNote } from '../../../components/NoteProvider';
import PaginationMoon from '../../../components/PaginationMoon';
import DownIco from '../../../components/icons/DownIco';
import UpIco from '../../../components/icons/UpIco';
import { useLoading } from '../../../nav/AppContainer';

const USER_TYPE = {
  TEACHER: '교수',
  STAFF: '튜터',
  MANAGER: '행정실',
  ADMIN: '지원실',
  USER: '원우',
  CP: 'CP',
  EXAMSTAFF: '시험감독관',
};

export default function LmsAccountsIndex() {
  const note = useNote();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(queryString.parse(window.location.search));
  const [isSelectInput, setIsSelectInput] = useState(false);

  useEffect(() => {
    setSearchParams(params);
  }, [params]);

  const [data, setData] = useState();
  const loadData = useCallback(async () => {
    const params = queryString.parse(window.location.search);
    const { data } = await restApi.get('/admin/accounts', {
      params: { ...params, limit: 10, sort: 'id', order: 'DESC' },
    });
    setData(data);
  }, [params]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newParams = {
      ...params,
      keywordType: e.target.keywordType.value,
      attending:
        e.target.typeSelector?.value?.toUpperCase() === 'USER'
          ? true
          : e.target.typeSelector?.value?.toUpperCase() === 'GRADUATE'
          ? false
          : undefined,
      type:
        e.target.typeSelector?.value?.toUpperCase() === 'GRADUATE'
          ? 'USER'
          : e.target.typeSelector?.value?.toUpperCase() ?? '',
      onlyNoSiteTeacher: false,
      period: e.target.keywordType.value === 'period' ? e.target.keyword?.value : '',
      keyword: e.target.keywordType.value === 'period' ? '' : e.target.keyword?.value ?? '',
    };
    if (newParams.attending === undefined) {
      delete newParams.attending;
    }
    setParams(newParams);
  };

  const handleDownloadSample = () => {
    FileSaver.saveAs('/assets/xls/upload_accounts.xlsx', '계정/권한관리_업로드양식.xlsx');
  };

  const [, setLoading] = useLoading();
  const handleDownloadXls = async () => {
    setLoading(true);
    const filename = `회원_${dayjs().format('YYYYMMDD')}`;
    try {
      const { data } = await restApi.get(`/accounts/xlsx`, {
        params: { ...params },
        responseType: 'arraybuffer',
      });
      FileSaver.saveAs(new Blob([data]), `${filename}.xlsx`);
    } catch (e) {
      alert('서버와 연결이 올바르지 않습니다.');
      console.warn(e);
    }
    setLoading(false);
  };

  const [uploadForm, setUploadForm] = useState(0);

  const handleUploadSubmit = async (e) => {
    e.preventDefault();

    const files = e.target.filedata.files;
    if (files.length === 0) {
      alert('파일선택은 필수항목입니다.');
      return;
    } else if (!files[0].name.endsWith('.xlsx')) {
      alert('허용된 파일은 xlsx입니다.');
      return;
    }

    setUploadForm(2);
    try {
      const formData = new FormData();
      formData.append('file', files[0]);

      const { data } = await restApi.post(`/accounts/xlsx`, formData);
      if (data.error) {
        alert(
          '아래 사유로 엑셀업로드가 불가능합니다.\n===================\n' +
            data.message +
            '\n-' +
            data.items?.join('\n -'),
        );
        setUploadForm(1);
      } else {
        const msg =
          '엑셀 업로드 시 다음과 같은 변동사항이 있습니다.\n\n' +
          `신규 추가 레코드 : ${data.creates.length}건\n` +
          `수정 예정 레코드 : ${data.updates.length}건` +
          '\n진행하시겠습니까?';
        if (!window.confirm(msg)) {
          setUploadForm(1);
          return;
        }

        await Promise.all(
          data.creates
            ?.map((x) => restApi.post(`/accounts/xlsx/items`, x))
            ?.concat(data.updates?.map((x) => restApi.put(`/accounts/xlsx/items`, x))),
        );
        alert('적용되었습니다.');
        await loadData();
        setUploadForm(0);
      }
    } catch (e) {
      console.warn(e);
      alert('서버와 연결이 올바르지 않습니다.');
      setUploadForm(1);
    }
  };

  return (
    <div className="content-wrapper container-xxl p-0">
      {/* header s */}
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="col-12">
              <h2 className="content-header-title float-start mb-0">계정/권한관리</h2>
              <div className="breadcrumb-wrapper">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">계정/권한관리</li>
                  <li className="breadcrumb-item active">계정관리</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // header e */}
      {/* con s */}
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <Link className={`nav-link active`} role="tab" to="/lms/accounts">
            계정관리
          </Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link`} role="tab" to="/lms/accounts/permission">
            권한설정
          </Link>
        </li>
      </ul>
      {/* Bordered table start s */}

      <div className={`row `}>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="col-md-6 col-12" style={{ display: 'flex', gap: '10px' }}>
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect"
                  onClick={handleDownloadSample}
                >
                  엑셀양식 파일
                </button>
                <button
                  type="button"
                  className="btn btn-outline-dark btn-doc waves-effect"
                  onClick={() => setUploadForm(1)}
                >
                  엑셀 업로드 <UpIco />
                </button>

                <button type="button" className="btn btn-outline-dark btn-doc waves-effect" onClick={handleDownloadXls}>
                  엑셀 다운로드 <DownIco />
                </button>
              </div>
              <div className="col-md-6 col-12 ms-auto">
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <select
                      className="form-select"
                      id="selectDefault"
                      name="keywordType"
                      defaultValue={params.keywordType}
                      onChange={({ target }) => {
                        if (target.value == 'type') {
                          setIsSelectInput(true);
                        } else {
                          setIsSelectInput(false);
                        }
                      }}
                    >
                      <option value={'period'}>기수</option>
                      <option value={'name'}>이름</option>
                      <option value={'idno'}>학번</option>
                      <option value={'type'}>구분</option>
                    </select>
                    {isSelectInput ? (
                      <select
                        className="form-select"
                        style={{ flex: '1 1 auto' }}
                        defaultValue={'student'}
                        name="typeSelector"
                      >
                        <option value={'user'}>원우(재학생)</option>
                        <option value={'graduate'}>원우(졸업생)</option>
                        <option value={'teacher'}>교수</option>
                        <option value={'staff'}>튜터</option>
                        <option value={'manager'}>행정실</option>
                        <option value={'admin'}>지원실</option>
                        <option value={'examstaff'}>시험감독관</option>
                      </select>
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Amount"
                        name="keyword"
                        defaultValue={params.keyword}
                      />
                    )}
                    <button className="btn btn-outline-primary waves-effect" type="submit">
                      검색
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="table-responsive scrollbox2">
              <table className="table table-bordered visual-box t-padding2">
                <colgroup>
                  <col style={{ width: 60 }} />
                  <col style={{ width: 150 }} />
                  <col style={{ width: 150 }} />
                  <col style={{ width: 100 }} />
                  <col style={{ width: 120 }} />
                  <col style={{ width: 150 }}/>
                  <col style={{ width: 80 }} />
                  <col style={{ width: 160 }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>구분</th>
                    <th>이름</th>
                    <th>기수</th>
                    <th>학번(아이디)</th>
                    <th>이메일</th>
                    <th>근무지</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {data?.content.map((item, index) => (
                    <tr key={item.id}>
                      <td className="txt-c">{index + 1 + data?.size * data?.number}</td>
                      <td className="txt-c">
                        {item.type === 'USER'
                          ? item.isLoa ?  '원우(휴학생)':`${USER_TYPE[item.type]}(${item.attending ? '재학생' : '졸업생'})`
                          : USER_TYPE[item.type]}
                      </td>
                      <td className="txt-c">
                        <Link to={`/lms/accounts/${item.id}`}>{item.name}</Link>
                      </td>
                      <td className="txt-c">{item.period && `IMBA ${item.period}기`}</td>
                      <td className="txt-c">{item.idno && item.idno != null ? `${item.idno}` : ' - '}({item.userId})</td>
                      <td className="txt-c">{item.email}</td>
                      <td className="txt-c">{item.isForeign ? (item.area ? item.area.join(' ') : '해외') : '국내'}</td>
                      <td className="txt-c">
                        <button
                          type="submit"
                          className="btn btn-outline-primary btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#btnSend"
                          onClick={() => {
                            note.send([item]);
                          }}
                        >
                          쪽지
                        </button>

                        <a href={`mailto:${item.email}`}>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#btnSend"
                            style={{ marginLeft: '10px' }}
                          >
                            메일
                          </button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 txt-r">
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-float waves-light"
              onClick={() => {
                navigate('/lms/accounts/write');
              }}
            >
              등록
            </button>
          </div>

          {/* pagination s */}
          <nav aria-label="Page navigation">
            <PaginationMoon data={data} onClick={(page) => setParams((x) => ({ ...x, page }))} />
          </nav>
          {/* // pagination e */}
        </div>
        {/* Bordered table end e */}
      </div>

      {/* // con e */}
      <Modal className={'fade text-start modal-primary show'} visible={!!uploadForm}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">계정정보 업로드</h5>
              {uploadForm === 1 && <button type="button" className="btn-close" onClick={() => setUploadForm(0)} />}
            </div>
            <div className="modal-body">
              {uploadForm === 1 ? (
                <form onSubmit={handleUploadSubmit}>
                  <div className="row mt-1">
                    <div className="col-12 mb-md-0">
                      <p>
                        엑셀을 업로드하여 다수의 정보를 한번에 입력할 수 있습니다.
                        <br />
                        정해진 엑셀 양식에 값을 입력하여 업로드 하세요 ( 지원하는 파일 양식 : xlsx)
                      </p>
                    </div>
                    <div className="col-12 mb-md-0 txt-r">
                      <button
                        type="button"
                        className="btn btn-outline-dark btn-doc waves-effect"
                        onClick={handleDownloadSample}
                      >
                        엑셀양식 파일
                      </button>
                    </div>
                    <div className="d-flex flex-column mt-2 mb-2">
                      <div className="featured-info">
                        <input className="form-control" type="file" name={'filedata'} />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary waves-effect waves-float waves-light">
                      저장
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary waves-effect"
                      onClick={() => setUploadForm(0)}
                    >
                      취소
                    </button>
                  </div>
                </form>
              ) : (
                <div className="spinner-wrap">
                  <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p>
                    원우정보를 DB에 업로드 중입니다.
                    <br />
                    잠시만 기다려 주십시오
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
